import { useState } from 'react'
import strings from './strings'

export function App() {
  const [formSent, setFormSent] = useState(false)
  const submitForm = async (e) => {
    e.preventDefault() // Prevent default form submit behavior
    const form = e.target
    const url = 'https://a9wfkhvu8i.execute-api.eu-central-1.amazonaws.com/production/inbox'

    const formData = new FormData(form)
    const jsonObject = { form: 'QRQ+ Form' }

    for (const [key, value] of formData.entries()) {
      jsonObject[key] = value
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonObject),
      })

      if (!response.ok) {
        throw new Error('Request failed')
      }
      setFormSent(true)
    } catch (error) {
      console.error(error)
    }
  }

  const unlimited = <span className="">{strings['Unlimited']}</span>
  const checkIcon = (
    <svg className="w-5 h-5  text-[#379cfa]" fill="currentColor" viewBox="0 0 20 20">
      <path d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" />
    </svg>
  )
  const minusIcon = (
    <svg className="w-5 h-5  text-black" fill="currentColor" viewBox="0 0 20 20">
      <path d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" />
    </svg>
  )

  const plans = [
    {
      name: 'QRQ',
      price: strings['Free'],
    },
    {
      name: 'QRQ+',
      price: strings['$280'],
    },
    {
      name: 'QRQ+ Premium',
      price: strings['$740'],
    },
  ]

  const tables = [
    {
      label: strings['Traffic'],
      items: [
        {
          name: strings['Queues'],
          QRQ: unlimited,
          'QRQ+': unlimited,
          'QRQ+ Premium': unlimited,
        },
        {
          name: strings['Service locations'],
          QRQ: unlimited,
          'QRQ+': unlimited,
          'QRQ+ Premium': unlimited,
        },
        {
          name: strings['Monthly visitors'],
          QRQ: unlimited,
          'QRQ+': unlimited,
          'QRQ+ Premium': unlimited,
        },
      ],
    },
    {
      label: strings['Features'],
      items: [
        {
          name: strings['Waiting room screen'],
          QRQ: checkIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Multilingual customer UI'],
          QRQ: checkIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Counters-per-queue'],
          QRQ: '1',
          'QRQ+': strings['Unlimited'],
          'QRQ+ Premium': strings['Unlimited'],
        },
        {
          name: strings['Multi-service queues'],
          QRQ: minusIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Push and IM apps notifications'],
          QRQ: checkIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['SMS & WhatsApp notifications'],
          QRQ: minusIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Customer authentication'],
          QRQ: minusIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Two-way messaging'],
          QRQ: minusIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Self check-in kiosk UI'],
          QRQ: minusIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Feedback forms and NPS'],
          QRQ: minusIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
      ],
    },
    {
      label: strings['Appearance'],
      items: [
        {
          name: strings['Custom branding'],
          QRQ: minusIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Color and font customizations'],
          QRQ: minusIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Custom domain'],
          QRQ: minusIcon,
          'QRQ+': (
            <>
              <span className="text-[#379cfa]">name</span>.qrq.app
            </>
          ),
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Custom UI design'],
          QRQ: minusIcon,
          'QRQ+': minusIcon,
          'QRQ+ Premium': checkIcon,
        },
      ],
    },
    {
      label: strings['Analytics'],
      items: [
        {
          name: strings['Performance reports'],
          QRQ: minusIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Service alerts'],
          QRQ: minusIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Data export'],
          QRQ: minusIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Custom analytics'],
          QRQ: minusIcon,
          'QRQ+': minusIcon,
          'QRQ+ Premium': checkIcon,
        },
      ],
    },
    {
      label: strings['Integrations'],
      items: [
        {
          name: strings['API and Webhooks'],
          QRQ: minusIcon,
          'QRQ+': minusIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Custom integrations'],
          QRQ: minusIcon,
          'QRQ+': minusIcon,
          'QRQ+ Premium': checkIcon,
        },
      ],
    },
    {
      label: strings['Support'],
      items: [
        {
          name: strings['Email'],
          QRQ: checkIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Onboarding'],
          QRQ: minusIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Live chat'],
          QRQ: minusIcon,
          'QRQ+': checkIcon,
          'QRQ+ Premium': checkIcon,
        },
        {
          name: strings['Dedicated account manager'],
          QRQ: minusIcon,
          'QRQ+': minusIcon,
          'QRQ+ Premium': checkIcon,
        },
      ],
    },
  ]

  const testimonials = [
    {
      avatar: new URL('./resources/sunny.jpg', import.meta.url),
      name: strings['Chanasinj Sachdev'],
      title: 'Creative Director of Prontodenim.com',
      quote: strings['sunnyTestimonial'],
    },
    {
      avatar: new URL('./resources/yana.jpg', import.meta.url),
      name: strings['Yana Grigorieva'],
      title: 'Project Manager of 7rivers.asia',
      quote: strings['yanaTestimonial'],
    },
  ]

  const [selectedPlan, setSelectedPlan] = useState('QRQ+')
  const [modal, setModal] = useState(false)

  const contactMethods = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
          />
        </svg>
      ),
      contact: 'sales@qrq.app',
    },
  ]

  return (
    <>
      <section className="pt-14 pb-8 text-black">
        <div className="max-w-[900px] m-auto">
          <div className="relative mx-auto space-y-2 px-4 md:px-0">
            <h3 className="text-[#379cfa] font-semibold">{strings['Pricing']}</h3>
            <p className="text-black text-[38px] sm:text-[45px] font-extrabold leading-[1.1em] tracking-[-.03em]">
              {strings['Upgrade to QRQ+']}
            </p>
            <div className=" text-black text-[24px] tracking-[-.03em] ">
              <p>{strings['Get full-fledged customer flow solution tailored for you']}</p>
            </div>
          </div>
          <div className="mt-8">
            <div className="sticky top-0 py-6 border-b-[.5px] bg-white">
              <div className="max-w-screen-xl mx-auto relative">
                <ul className="ml-auto flex gap-x-6 px-4 md:px-8 lg:max-w-2xl">
                  {plans.map((item, idx) => (
                    <li
                      key={idx}
                      className={`space-y-4 w-full ${
                        item.name != selectedPlan ? 'hidden lg:block' : ''
                      }`}
                    >
                      <div className="flex items-center justify-between">
                        <span className="text-black mb-[-12px] font-normal">{item.name}</span>
                        <div className="relative lg:hidden top-[6px]">
                          <svg
                            className="w-5 h-5 text-black absolute right-0 inset-y-0 my-auto"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <select
                            value={strings['Switch plan']}
                            className="bg-transparent appearance-none outline-none px-8 cursor-pointer"
                            onChange={(e) => setSelectedPlan(e.target.value)}
                          >
                            <option disabled selected>
                              {strings['Switch plan']}
                            </option>
                            {plans.map((option, idx) => (
                              <option key={idx}>{option.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div
                        className={
                          'text-black ' +
                          (/\₽|Бесплатно/.test(item.price)
                            ? 'text-xl font-bold'
                            : 'text-3xl font-extrabold')
                        }
                      >
                        {item.price}{' '}
                        {/\$|\₽/.test(item.price) && (
                          <span className="text-base relative top-[-2px] text-black font-normal">
                            /{strings['month']}
                          </span>
                        )}
                      </div>
                      <button
                        onClick={
                          idx === 0
                            ? () => window.open('https://qrq.app/create')
                            : () => setModal(true)
                        }
                        className={
                          'px-3 py-3 rounded-full w-full font-semibold text-sm duration-150 ' +
                          (idx === 0
                            ? ' text-[#379cfa] bg-[#e7f3fe] hover:opacity-80'
                            : 'text-white bg-[#379cfa] hover:opacity-80 active:bg-blue-500')
                        }
                      >
                        {idx === 0 ? strings['Create queue'] : strings['Upgrade']}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="max-w-screen-xl px-4 mx-auto mt-10 space-y-4 overflow-auto md:overflow-visible md:px-8 lg:px-0">
              {tables.map((table, idx) => (
                <table key={idx} className="w-full table-auto text-sm text-left">
                  <thead className="text-black font-medium border-b borde-[.5px]">
                    <tr>
                      <th className="z-20 top-28 py-3 lg:sticky">
                        <div className="flex items-center gap-x-3">
                          <h4 className="text-black text-2xl font-extrabold">{table.label}</h4>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-black text-base divide-y">
                    {table.items.map((item, idx) => (
                      <>
                        <tr key={idx}>
                          <td className="px-0 py-2 whitespace-nowrap w-[268px]">{item.name}</td>
                          {/* For large devices */}
                          <td className="px-0 py-2 w-[228px] whitespace-nowrap hidden lg:table-cell">
                            {item.QRQ}
                          </td>
                          <td className="px-0 py-2 w-[228px] whitespace-nowrap hidden lg:table-cell">
                            {item['QRQ+']}
                          </td>
                          <td className="px-0 py-2 w-[228px] whitespace-nowrap hidden lg:table-cell">
                            {item['QRQ+ Premium']}
                          </td>
                          {/* For small devices */}
                          <td className=" px-0 py-2 whitespace-nowrap lg:hidden">
                            {item[selectedPlan]}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section id="sales" className="max-w-[900px] mx-auto py-4 px-4 md:px-0 pb-20">
        <div className="max-w-lg">
          <div className="py-4">
            <h3 className="text-2xl text-black font-extrabold md:text-3xl tracking-[-.02em]">
              {strings['Need a custom solution?']}
            </h3>
            <p className="text-black leading-relaxed mt-5 mb-2">{strings['We are here to help']}</p>
          </div>
          <a
            onClick={() => setModal(true)}
            className="cta-pr-btn px-4 py-2 text-[#379cfa] bg-[#e7f3fe] hover:opacity-80 font-medium  rounded-full inline-flex items-center"
            href="javascript:void()"
          >
            {strings['Let’s create something new']}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 ml-1 duration-150"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 7l5 5m0 0l-5 5m5-5H6"
              />
            </svg>
          </a>
        </div>

        <style jsx>{`
          .cta-pr-btn:hover svg {
            transform: translateX(5px);
          }
        `}</style>
      </section>
      <section className="py-14 pb-20 bg-gray-50">
        <div className="max-w-[900px] mx-auto px-4 md:px-0">
          <div className="max-w-2xl">
            <h3 className="text-black leading-[1.1em] text-[42px] sm:text-[45px] font-extrabold tracking-[-.03em]">
              {strings['What Our Customers Say']}
            </h3>
          </div>
          <div className="mt-8">
            <ul className="grid gap-6 sm:grid-cols-2 lg:grid-cols-2">
              {testimonials.map((item, idx) => (
                <li key={idx} className="bg-white p-8 rounded-3xl">
                  <figure>
                    <div className="flex items-center gap-x-4">
                      <img src={item.avatar} className="w-16 h-16 rounded-full" />
                      <div>
                        <span className="block text-black font-semibold">{item.name}</span>
                        <span className="block text-black text-sm mt-0.5">{item.title}</span>
                      </div>
                    </div>
                    <blockquote>
                      <p className="mt-6 text-black">{item.quote}</p>
                    </blockquote>
                  </figure>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <main className="py-14 bg-[#379cfa] pb-20 text-white">
        <div className="max-w-[900px] mx-auto px-4 md:px-0">
          <h3 className="text-[45px] leading-[1.1em] font-extrabold tracking-[-.03em]">
            {strings['Contact Sales']}
          </h3>
          <div className="max-w-lg mx-auto mt-8 gap-12 justify-between lg:flex lg:max-w-none">
            <div className="max-w-md space-y-3">
              <p className="text-lg">{strings['pleaseFillTheForm']}</p>
              <div>
                <ul className="mt-6 flex flex-wrap gap-x-10 gap-y-6 items-center">
                  {contactMethods.map((item, idx) => (
                    <li key={idx} className="flex items-center gap-x-3 opacity-80">
                      <div className="flex-none ">{item.icon}</div>
                      <p>{item.contact}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="flex-1 sm:max-w-lg lg:max-w-lg mt-12 md:mt-[-24px]">
              {formSent ? (
                <div className="text-center py-14 px-8 rounded-3xl bg-[#6eb9ff]">
                  {strings["Thank you! We've received your request and will contact you shortly"]}
                </div>
              ) : (
                <form onSubmit={submitForm} className="space-y-5">
                  <div>
                    <label className="font-medium">{strings['Your name']}</label>
                    <input
                      type="text"
                      required
                      name="Name"
                      className="w-full mt-2 px-8 py-3  bg-transparent outline-none border  shadow-sm rounded-full"
                    />
                  </div>
                  <div>
                    <label className="font-medium">Email</label>
                    <input
                      type="email"
                      required
                      name="Email"
                      className="w-full mt-2 px-8 py-3 bg-transparent outline-none border  shadow-sm rounded-full"
                    />
                  </div>

                  <button className="w-full px-4 py-3 text-black font-medium bg-white hover:opacity-90 rounded-full duration-150">
                    {strings['Upgrade to QRQ+']}
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </main>
      <section className="py-14 pb-14 bg-white">
        <div className="max-w-[900px] mx-auto px-4 md:px-0">
          <div className="items-center justify-between sm:flex">
            <div className="mt-4 sm:mt-0 text-gray-500">© 2023 KADR LLC. BIN 221140024256</div>
            <div className="mt-6 sm:mt-0">
              <ul className="flex gap-4 items-center space-x-4">
                <li className="w-[86px]  hover:opacity-70">
                  <a target="__blank" href="https://incubator.builduplabs.com/">
                    <img src={new URL('./resources/bul.png', import.meta.url)} alt="logo-bul" />
                  </a>
                </li>
                <li className="w-[200px] hover:opacity-70">
                  <a target="__blank" href="https://astanahub.com/en/l/h/about-us">
                    <img
                      src={new URL('./resources/ah.png', import.meta.url)}
                      alt="logo-astana-hub"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {modal ? (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={() => setModal(false)}
          ></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto rounded-3xl bg-white shadow-lg">
              <div className="flex justify-end">
                <button
                  className="p-2 text-gray-400 rounded-full hover:bg-gray-100"
                  onClick={() => setModal(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <div className="max-w-sm mx-auto py-3 space-y-3 text-center">
                <h4 className="text-[35px] font-extrabold tracking-[-.03em] leading-[1.1em]">
                  {strings['Contact Sales']}
                </h4>
                <p className="text-[16px] text-black">{strings['pleaseFillTheForm']}</p>
                <div className="flex-1 text-left sm:max-w-lg lg:max-w-lg mt-12 pb-10 md:mt-[-24px]">
                  {formSent ? (
                    <div className="text-center py-14 px-8 mt-8 rounded-3xl bg-slate-50">
                      {
                        strings[
                          "Thank you! We've received your request and will contact you shortly"
                        ]
                      }
                    </div>
                  ) : (
                    <form onSubmit={submitForm} className="space-y-5">
                      <div>
                        <label className="font-medium">{strings['Your name']}</label>
                        <input
                          type="text"
                          name="Name"
                          required
                          className="w-full mt-2 px-8 py-3  bg-transparent outline-none border  shadow-sm rounded-full"
                        />
                      </div>
                      <div>
                        <label className="font-medium">{strings['Email']}</label>
                        <input
                          type="email"
                          name="Email"
                          required
                          className="w-full mt-2 px-8 py-3 bg-transparent outline-none border  shadow-sm rounded-full"
                        />
                      </div>

                      <button className="w-full px-4 py-3 font-medium  rounded-full duration-150 text-white bg-[#379cfa] hover:opacity-80 active:bg-blue-500">
                        {strings['Upgrade to QRQ+']}
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}
