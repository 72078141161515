const defaultLocale = 'en'
const browserLocale = navigator.language.replace(/-\w+/, '')

const strings = {
  ru: {
    Pricing: 'Тарифы',
    'Upgrade to QRQ+': 'Перейдите на QRQ+',
    'Get full-fledged customer flow solution tailored for you':
      '— внедрите гибкую платформу управления очередью',
    Free: 'Бесплатно',
    'Create queue': 'Создать очередь',
    Upgrade: 'Перейти',
    month: 'месяц',
    Unlimited: 'Безлимит',
    Traffic: 'Трафик',
    Queues: 'Количество очередей',
    'Service locations': 'Количество точек',
    'Monthly visitors': 'Посетителей в месяц',
    Features: 'Функции',
    'Waiting room screen': 'Интерфейс комнаты ожидания',
    'Multilingual customer UI': 'Мультиязычность',
    'Counters-per-queue': 'Окна обслуживания/очередь',
    'Multi-service queues': 'Классификация по услугам',
    'Push and IM apps notifications': 'Уведомления Push, Telegram, VK',
    'SMS & WhatsApp notifications': 'Уведомления SMS и WhatsApp',
    'Customer authentication': 'Авторизация посетителей',
    'Two-way messaging': 'Чат с посетителем',
    'Self check-in kiosk UI': 'Интерфейс для киоска chek-in',
    'Feedback forms and NPS': 'Опросы качества обслуживания',
    Appearance: 'Интерфейс',
    'Custom branding': 'Брендинг',
    'Color and font customizations': 'Изменение цветов и шрифтов',
    'Custom domain': 'Отдельное доменное имя',
    'Custom UI design': 'Доработки дизайна',
    Analytics: 'Аналитика',
    'Performance reports': 'Производительность персонала',
    'Service alerts': 'Уведомления',
    'Data export': 'Экспорт данных',
    'Custom analytics': 'Кастомная аналитика',
    Integrations: 'Интеграция',
    'API and Webhooks': 'API и Webhooks',
    'Custom integrations': 'Кастомные интеграции',
    Support: 'Поддержка',
    Email: 'Электронная почта',
    Onboarding: 'Онбординг',
    'Live chat': 'Чат со специалистом',
    'Dedicated account manager': 'Выделенный аккаунт менеджер',
    'What Our Customers Say': 'Что говорят наши клиенты',
    'Chanasinj Sachdev': 'Chanasinj Sachdev',
    'Yana Grigorieva': 'Яна Григориева',
    sunnyTestimonial:
      'QRQ team built a complex solution for our stores which utilizes face recognition and geofencing technologies. Boris and Elena, are top-notch professionals, and it has been a pleasure to work with them.',
    yanaTestimonial:
      'Мы обратились к QRQ для разработки электронной очереди на тест-драйв крупного автомобильного бренда адаптированный под наше мероприятие. У нас были очень сжатые сроки и команда QRQ смогла разработать для нас решение менее чем за неделю. К разработанному продукту были подробные инструкции для пользования, при этом команда была на связи 24/7 и во время самого мероприятия сопровождала нас в удаленном режиме. Рекомендуем!',
    'Need a custom solution?': 'Нужно уникальное решение?',
    'We are here to help':
      'Мы умеем создавать эффективное ПО для автоматизации процессов с применением ИИ и облачных технологий',
    'Let’s create something new': 'Связаться с нами',
    'Contact Sales': 'Связаться с нами',
    pleaseFillTheForm:
      'Заинтересовал наш сервис? — пожалуйста, заполните форму и мы свяжемся с вами',
    "Thank you! We've received your request and will contact you shortly":
      'Спасибо! Скоро мы свяжемся с вами по указанной почте',
    'Your name': 'Ваше имя',
    $280: '26 800₽',
    $740: 'от 70 000₽',
    'Switch plan': 'Другой тариф',
  },
  en: {
    Pricing: 'Pricing',
    'Upgrade to QRQ+': 'Upgrade to QRQ+',
    'Get full-fledged customer flow solution tailored for you':
      'Get full-fledged customer flow solution tailored for you',
    Free: 'Free',
    'Create queue': 'Create queue',
    Upgrade: 'Upgrade',
    month: 'month',
    Unlimited: 'Unlimited',
    Traffic: 'Traffic',
    Queues: 'Queues',
    'Service locations': 'Service locations',
    'Monthly visitors': 'Monthly visitors',
    'Waiting room screen': 'Waiting room screen',
    Features: 'Features',
    'Multilingual customer UI': 'Multilingual customer UI',
    'Counters-per-queue': 'Counters-per-queue',
    'Multi-service queues': 'Multi-service queues',
    'Push and IM apps notifications': 'Push and IM apps notifications',
    'SMS & WhatsApp notifications': 'SMS & WhatsApp notifications',
    'Customer authentication': 'Customer authentication',
    'Two-way messaging': 'Two-way messaging',
    'Self check-in kiosk UI': 'Self check-in kiosk UI',
    'Feedback forms and NPS': 'Feedback forms and NPS',
    Appearance: 'Appearance',
    'Custom branding': 'Custom branding',
    'Color and font customizations': 'Color and font customizations',
    'Custom domain': 'Custom domain',
    'Custom UI design': 'Custom UI design',
    Analytics: 'Analytics',
    'Performance reports': 'Performance reports',
    'Service alerts': 'Service alerts',
    'Data export': 'Data export',
    'Custom analytics': 'Custom analytics',
    Integrations: 'Integrations',
    'API and Webhooks': 'API and Webhooks',
    'Custom integrations': 'Custom integrations',
    Support: 'Support',
    Email: 'Email',
    Onboarding: 'Onboarding',
    'Live chat': 'Live chat',
    'Dedicated account manager': 'Dedicated account manager',
    'Chanasinj Sachdev': 'Chanasinj Sachdev',
    'Yana Grigorieva': 'Yana Grigorieva',
    sunnyTestimonial:
      'QRQ team built a complex solution for our stores which utilizes face recognition and geofencing technologies. Boris and Elena, are top-notch professionals, and it has been a pleasure to work with them.',
    yanaTestimonial:
      'We had a really though deadline with our BTL event for a major automotive brand and QRQ team were able to build a custom queue management solution for us in under a week! Definitely recommend.',
    'Need a custom solution?': 'Need a custom solution?',
    'We are here to help':
      'We are here to help! The team behind QRQ has expertise in customer flow management, eventtech, martech and AI software.',
    'Let’s create something new': 'Let’s create something new',
    'Contact Sales': 'Contact Sales',
    'What Our Customers Say': 'What Our Customers Say',
    pleaseFillTheForm:
      'We will be happy to onboard you. Please fill the form and we will contact you shortly',
    "Thank you! We've received your request and will contact you shortly":
      "Thank you! We've received your request and will contact you shortly",
    'Your name': 'Your name',
    $280: '$280',
    $740: '$740',
    'Switch plan': 'Switch plan',
  },
}

export default strings[strings[browserLocale] ? browserLocale : defaultLocale]
